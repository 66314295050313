<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card :title="$t('Checkk')">
          <label>{{ $t('search') }}</label>
          <b-row>
            <b-col
              cols="4"
            >
              <label>{{ $t('Checkk No') }}</label>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="data.filter1 "
                  type="search"
                  :placeholder="$t('Type to Search')"
                  @keyup.enter="onEnter1"
                />
              </b-input-group>
            </b-col>
            <b-col
              cols="4"
            >
              <label>{{ $t('Chassis No') }}</label>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="data.filter2 "
                  type="search"
                  :placeholder="$t('Type to Search')"
                  @keyup.enter="onEnter2"
                />
              </b-input-group>
            </b-col>
            <b-col
              cols="4"
            >
              <label>{{ $t('In all') }}</label>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="data.filter3 "
                  type="search"
                  :placeholder="$t('Type to Search')"
                  @keyup.enter="onEnter3"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <br>
          <v-select
            v-model="data.checkk"
            :options="Checkks"
            :reduce="Checkks => Checkks.id"
            label="No"
          >
            <template #option="{ No , chassis_number }">
              <span> {{ No }} - {{ chassis_number }} </span>
            </template>
          </v-select>
          <small
            v-if="errors.checkk"
            class="text-danger"
          >{{ errors.checkk[0] }}</small>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-card :title="$t('Checkk')">
          <b-row
            v-if="data.checkk"
          >
            <b-col
              v-for="el in ['no','No','chassis_number', 'chassis_number_type','owner_type1', 'created_year', 'first_registeraion',
                            'cabin_number','cabin_number_type','motor_number_type','motor_reserve_number','motor_reserve_number_type',
                            'motor_number','cabin_reserve_number_type','cabin_reserve_number','expiration_date','toll',
                            'color','fuel_type','car_type','motor_model','motor_manufacturer','wheel1','combustion1',
                            'light1','brake1','block_reason','block1','notes']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="SelectedCheckk(data.checkk)[el]"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-else
            class="text-center"
          >
            البيانات غير متاحة
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >

        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('color') }}</label>
                <b-form-input
                  v-model="data.color"
                  class="mt-2"
                  :state="errors.color ?false:null"
                  :placeholder="$t('color')"
                />
                <small
                  v-if="errors.color"
                  class="text-danger"
                >{{ errors.color[0] }}</small>
                <label>{{ $t('color') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('fuel_type') }}</label>
                <b-form-select
                  v-model="data.fuel_type"
                  :options="fueltypeoptions"
                />
                <small
                  v-if="errors.fuel_type"
                  class="text-danger"
                >{{ errors.fuel_type[0] }}</small>
              </div>
            </b-col>
            <b-col
              v-if="data.renewData === 'renew'"
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Expiration_date') }}</label>
                <b-form-datepicker
                  v-model="data.expiration_date"
                  :placeholder="$t('Expiration_date')"
                  :state="errors.expiration_date ?false:null"
                />
                <small
                  v-if="errors.expiration_date"
                  class="text-danger"
                >{{ errors.expiration_date[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.other_fee"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('other_fee')"
                  :state="errors.other_fee ?false:null"
                  :class="errors.other_fee ?'is-invalid':null"
                />
                <small
                  v-if="errors.other_fee"
                  class="text-danger"
                >{{ errors.other_fee[0] }}</small>
                <label>{{ $t('other_fee') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <div class="form-label-group">
                <label>{{ $t('notes') }}</label>
                <b-form-input
                  v-model="data.notes"
                  class="mt-2"
                  :state="errors.notes ?false:null"
                  :placeholder="$t('notes')"
                />
                <small
                  v-if="errors.notes"
                  class="text-danger"
                >{{ errors.notes[0] }}</small>
                <label>{{ $t('notes') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('public/specific') }}</label>
                <b-form-checkbox
                  v-model="data.owner_type"
                  :value="true"
                  :state="errors.owner_type ?false:null"
                >
                  {{ data.owner_type ? $t('specific') : $t('public') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.owner_type"
                class="text-danger"
              >{{ errors.owner_type[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('brake') }}</label>
                <b-form-checkbox
                  v-model="data.brake"
                  :value="true"
                  :state="errors.brake ?false:null"
                >
                  {{ data.brake ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.brake"
                class="text-danger"
              >{{ errors.brake[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('light') }}</label>
                <b-form-checkbox
                  v-model="data.light"
                  :value="true"
                  :state="errors.light ?false:null"
                >
                  {{ data.light ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.light"
                class="text-danger"
              >{{ errors.light[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('combustion') }}</label>
                <b-form-checkbox
                  v-model="data.combustion"
                  :value="true"
                  :state="errors.combustion ?false:null"
                >
                  {{ data.combustion ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.combustion"
                class="text-danger"
              >{{ errors.combustion[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('wheel') }}</label>
                <b-form-checkbox
                  v-model="data.wheel"
                  :value="true"
                  :state="errors.wheel ?false:null"
                >
                  {{ data.wheel ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.wheel"
                class="text-danger"
              >{{ errors.wheel[0] }}</small>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row><b-col
                 cols="12"
               >
                 <b-card :title="$t('invoice')">
                   {{ $t('renew_fee') }} : {{ this.data.renew_fee }}$<br>
                   {{ $t('other_fee') }} : {{ this.data.other_fee }}$<br>
                   {{ $t('Total') }} : {{ this.data.renew_fee }} + {{ this.data.other_fee }} ={{ parseFloat(this.data.renew_fee) + parseFloat(this.data.other_fee) }}$<br>
                   {{ $t('Total') }} : {{ Total }}<span>&#8378;</span><br>
                 </b-card>
               </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="resetData"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>

              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-col><b-col
        cols="12"
        md="8"
      /><b-col
        cols="12"
        md="4"
      >
        <b-row>
          <b-col
            v-if="haveAccess(['Set Company'])"
            cols="12"
          >
            <b-card :title="$t('Company')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.company"
                :options="Companies"
                :reduce="Companies => Companies.id"
                label="name"
              />
              <small
                v-if="errors.company"
                class="text-danger"
              >{{ errors.company[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            v-if="haveAccess(['Company Other'])"
            cols="12"
          >
            <b-card :title="$t('Company Fee Belong To')">
              <label>{{ $t('please select first ...') }}</label>
              <v-select
                v-model="data.company_from"
                :options="Companies"
                :reduce="Companies => Companies.id"
                label="name"
              />
              <small
                v-if="errors.company_from"
                class="text-danger"
              >{{ errors.company_from[0] }}</small>
              <label>{{ $t('please select second...') }}</label>
              <v-select
                v-model="data.company_to"
                :options="Companies"
                :reduce="Companies => Companies.id"
                label="name"
              />
              <small
                v-if="errors.company_to"
                class="text-danger"
              >{{ errors.company_to[0] }}</small>
            </b-card>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { value: 'Container', text: 'كونتينر' },
        { value: 'ROR', text: 'رورو' },
        { value: 'Land_shipping', text: 'شحن بري' },
        { value: 'Other', text: 'غير ذلك' },
      ],
      typeoptions: [
        { value: 'Normal', text: 'نظامية' },
        { value: 'Assa', text: 'تجميع' },
      ],
      fueltypeoptions: [
        { value: 'mazot', text: 'مازوت' },
        { value: 'benzin', text: 'بنزين' },
        { value: 'gaz', text: 'غاز' },
      ],
      data: {
        renewData: 'renew',
        created_year: '',
        car: null,
        company: null,
        check_in_date: `${this.moment().format('YYYY-MM-DD')}`,
        owner_type: true,
        brake: true,
        light: true,
        combustion: true,
        fee: 0,
        other_fee: 0,
        total: 0,
        wheel: true,
      },
      datanew: false,
      created_year: {
        N: '1992',
        P: '1993',
        R: '1994',
        S: '1995',
        T: '1996',
        V: '1997',
        W: '1998',
        X: '1999',
        Y: '2000',
        1: '2001',
        2: '2002',
        3: '2003',
        4: '2004',
        5: '2005',
        6: '2006',
        7: '2007',
        8: '2008',
        9: '2009',
        A: '2010',
        B: '2011',
        C: '2012',
        D: '2013',
        E: '2014',
        F: '2015',
        G: '2016',
        H: '2017',
        J: '2018',
        K: '2019',
        L: '2020',
        M: '2021',
      },
      errors: [],
      fileErrors: '',

    }
  },
  computed: {
    pagination() {
      return `page=${this.currentPage}&search=${this.filter1}`
    },
    Total() {
      let totalfee = 0
      totalfee = parseFloat(this.data.fee) + (parseFloat(this.data.other_fee) * parseFloat(this.Tolls.dollar))
      return totalfee
    },
    Companies() {
      return this.$store.getters['company/GetElements']
    },
    Checkks() {
      return this.$store.getters['checkk/GetElements']
    },
    Tolls() { return this.$store.getters['toll/GetElements'] },
  },
  watch: {
    // eslint-disable-next-line func-names
    'data.other_fee': function (val) {
      if (!val) {
        this.data.other_fee = 0
      }
    },
    'data.company': function (val) {
      if (!val) {
        this.clearData()
      }
    },
    'data.checkk': function (val) {
      if (val) {
        this.setData(val)
        this.data.fee = 0
        if (this.data.fee < 1 && this.data.created_year > 1900) {
          if (this.data.created_year < 2006) {
            if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 1600) {
              this.data.fee = (parseFloat(this.Tolls.tourismOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourismOneRenew)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourismTwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourismTwoRenew)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power > 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourismThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourismThreeRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.transportOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transportOneRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.transportTwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transportTwoRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.transportThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transportThreeRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.spicialOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialOneRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialTwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialTwoRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialThreeRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 14) {
              this.data.fee = (parseFloat(this.Tolls.busOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.busOneRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 30) {
              this.data.fee = (parseFloat(this.Tolls.busTwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.busTwoRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger > 30) {
              this.data.fee = (parseFloat(this.Tolls.busThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.busThreeRenew)
            } else if (this.data.carr.car_type === 'goverment') {
              this.data.fee = (parseFloat(this.Tolls.govermentOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.govermentOneRenew)
            } else if (this.data.carr.car_type === 'motor') {
              this.data.fee = (parseFloat(this.Tolls.motorOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.motorOneRenew)
            }
          } else if (this.data.created_year > 2005) {
            if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 1600) {
              this.data.fee = (parseFloat(this.Tolls.tourism2OneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourism2OneRenew)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourism2TwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourism2TwoRenew)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power > 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourism2ThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourism2ThreeRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.transport2OneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transport2OneRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.transport2TwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transport2TwoRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.transport2ThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transport2ThreeRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.spicialOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialOneRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialTwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialTwoRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialThreeRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 14) {
              this.data.fee = (parseFloat(this.Tolls.bus2OneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.bus2OneRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 30) {
              this.data.fee = (parseFloat(this.Tolls.bus2TwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.bus2TwoRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger > 30) {
              this.data.fee = (parseFloat(this.Tolls.bus2ThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.bus2ThreeRenew)
            } else if (this.data.carr.car_type === 'goverment') {
              this.data.fee = (parseFloat(this.Tolls.govermentOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.govermentOneRenew)
            } else if (this.data.carr.car_type === 'motor') {
              this.data.fee = (parseFloat(this.Tolls.motorOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.motorOneRenew)
            }
          }
        }
        this.data.fee = parseFloat(this.data.fee)
      } else {
        this.clearData()
      }
    },
    // eslint-disable-next-line func-names
  },
  created() {
    this.$store.dispatch('checkk/GetElements')
    this.$store.dispatch('company/GetElements')
    this.$store.dispatch('owwner/GetAvailableElements')
    this.$store.dispatch('toll/GetPluckedElements')
  },
  methods: {
    onEnter() {
      this.currentPage = 1
      this.getElementdata()
    },
    onEnter1() {
      this.currentPage = 1
      this.getElementdata1()
      this.clearData()
      this.data.filter1 = ''
    },
    onEnter2() {
      this.currentPage = 1
      this.getElementdata2()
      this.clearData()
      this.data.filter2 = ''
    },
    onEnter3() {
      this.currentPage = 1
      this.getElementdata3()
      this.clearData()
      this.data.filter3 = ''
    },
    setData(val) {
      this.data.renewData = 'renew'
      this.data.brake = true
      this.data.light = true
      this.data.combustion = true
      this.data.wheel = true
      this.data1 = this.Checkks.find(el => el.id === val)
      this.data.chassis_number = this.data1.chassis_number
      this.data.color = this.data1.color
      this.data.expiration_date = this.moment(this.data1.expiration_date).add(12, 'months').format('YYYY-MM-DD')
      this.data.brake = this.data1.brake
      this.data.light = this.data1.light
      this.data.combustion = this.data1.combustion
      this.data.wheel = this.data1.wheel
      this.data.company = this.data1.company_id
      this.data.owwner = this.data1.owwner
      this.data.created_year = this.data1.created_year
      this.data.carr = this.data1.carr_data
      this.data.owner_type = this.data1.owner_type
      if (this.data1.fuel_type === 'بنزين') { this.data.fuel_type = 'benzin' } else if (this.data1.fuel_type === 'مازوت') { this.data.fuel_type = 'mazot' } else if (this.data1.fuel_type === 'غاز') { this.data.fuel_type = 'gaz' }
    },
    getElementdata1() {
      this.$store.dispatch('checkk/GetElementsByCheckkNo', `page=1&search=${this.data.filter1}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.checkk = response.data.checkks[0].id
        this.setData(this.data.checkk)
      })
    },
    getElementdata2() {
      this.$store.dispatch('checkk/GetElementsByChassisNo', `page=1&search=${this.data.filter2}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.checkk = response.data.checkks[0].id
        this.setData(this.data.checkk)
      })
    },
    getElementdata3() {
      this.$store.dispatch('checkk/GetElementsByAll', `page=1&search=${this.data.filter3}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.checkk = response.data.checkks[0].id
        this.setData(this.data.checkk)
      })
    },
    clearData() {
      this.data = {
        fee: 0,
        total: 0,
        renewData: 'renew',
        other_fee: 0,
        datanew: true,
        owner_type: true,
        brake: true,
        checkk: null,
        company: null,
        light: true,
        combustion: true,
        wheel: true,
        carr: null,
      }
    },
    SelectedCheckk(val) {
      return this.Checkks.find(el => el.id === val)
    },
    getData(val) {
      this.$store.dispatch('checkk/GetAvailableElements', val).then(() => {
        this.data = this.Element
      })
    },
    resetData() {
      this.data = {
        fee: 0,
        total: 0,
        renewData: 'renew',
        other_fee: 0,
        datanew: true,
        owner_type: true,
        brake: true,

        light: true,
        combustion: true,
        wheel: true,
      }
      this.errors = []
    },
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.data.fee = parseInt(this.Total, 10)
      this.data.owner = this.data.owwner
      this.errors = []
      this.$store.dispatch('renew/Create', this.data).then(() => {
        this.clearData()
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
